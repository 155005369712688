<template>
  <div class="Scales bg-white">
    <Row class="px-1" :gutter="16">
      <i-col span="8" class="align-justify">
        <Form ref="formValidate" :model="task">
          <Row :gutter="32">
            <i-col span="24">
              <FormItem label="任务名称" required label-position="top">
                <Input
                  size="large"
                  v-model="task.taskName"
                  maxlength="15"
                  show-word-limit
                  placeholder="请输入任务名称"
                />
              </FormItem>
            </i-col>
          </Row>
          <FormItem label="任务说明" required label-position="top">
            <Input
              v-model="task.taskDesc"
              maxlength="15"
              size="large"
              show-word-limit
              placeholder="请输入任务说明"
            />
          </FormItem>
          <Row :gutter="32">
            <i-col span="24">
              <FormItem label="选择量表" required label-position="top">
                <Table
                  :border="false"
                  :show-header="false"
                  no-data-text="暂未选择"
                  :columns="checkScaleColumns"
                  :data="checkScaleData"
                  style="width:100%"
                >
                  <div slot-scope="{row}" slot="action">
                    <Button
                      type="primary"
                      size="small"
                      icon="md-remove-circle"
                      @click="removeScale(row)"
                    ></Button>
                  </div>
                </Table>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="32">
            <i-col span="24">
              <FormItem label="结束时间" required label-position="top">
                <DatePicker
                  size="large"
                  :options="disabledDate"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  v-model="task.taskDay"
                  placeholder="选择结束时间"
                  style="display: block"
                ></DatePicker>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="32" v-if="task.r_type == 2">
            <i-col span="24">
              <FormItem label="选择所属单位/部门" required label-position="top">
                <Button type="text" style="color:#2d8cf0" @click="selectUser">筛选测试用户</Button>
                <div style="margin-top:15px">
                  <Timeline v-for="item in conditionItem" :key="item.key">
                    <TimelineItem>{{item}}</TimelineItem>
                  </Timeline>
                </div>
              </FormItem>
            </i-col>
          </Row>
        </Form>
        <div class="demo-drawer-footer" style="float:left">
          <Button type="primary" @click="releaseTask">发布</Button>
        </div>
      </i-col>
      <i-col span="8" class="align-right">
        <i-col span="24" class="align-justify">
          <i-input
            v-model="scalekeyword"
            size="large"
            style="width: 15rem"
            class="el-inline mr-1"
            placeholder="输入量表名称进行查询"
          ></i-input>
          <Button type="primary" size="large" class="mr-1" @click="searchScale">搜索量表</Button>
        </i-col>
        <i-col span="24">
          <Spin size="large" fix v-if="scaleShow">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
            <div>正在加载...</div>
          </Spin>
          <div
            style="text-align:center;margin-top:30px;margin-bottom:30px"
            v-if="scaleData.length == 0"
          >
            <img src="../assets/nodata.png" />
            <h3>没有找到匹配的数据</h3>
          </div>
          <List :loading="scalesearching" v-if="scaleData.length != 0">
            <ListItem v-for="(item,key) in scaleData" :key="key" :value="item.scaleId">
              <ListItemMeta :title="item.scaleName">
                <template slot="description">
                  <Poptip trigger="hover" word-wrap width="500">
                    <span slot="content" v-html="item.describe"></span>
                    <span style="color:#57a3f3;text-decoration:underline">量表简介</span>
                  </Poptip>
                </template>
              </ListItemMeta>
              <template slot="action">
                <li>
                  <Button type="primary" size="small" @click="selectScale(item)">选用</Button>
                </li>
              </template>
            </ListItem>
          </List>
          <Page
            ref="scalePagination"
            :total="scaleTotal"
            :page-size="scaleSize"
            @on-change="changeScalePage"
            @on-page-size-change="changeScaleSize"
            show-total
            class="p-1 align-right"
          />
        </i-col>
      </i-col>
    </Row>
    <Drawer title="筛选测试用户条件" v-model="draw" width="540" :mask-closable="false" :styles="styles">
      <Form :model="conditionData">
        <Row :gutter="8">
          <i-col span="24">
            <Alert>不选择则默认为全部，所属单位为必选项</Alert>
          </i-col>
          <i-col span="12">
            <FormItem label="出生日期（之后）" label-position="top">
              <DatePicker
                clearable
                v-model="conditionData.startDate"
                type="date"
                placeholder="请选择日期"
                style="display: block"
                placement="bottom"
              ></DatePicker>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="出生日期（之前）" label-position="top">
              <DatePicker
                clearable
                v-model="conditionData.endDate"
                type="date"
                placeholder="请选择日期"
                style="display: block"
                placement="bottom"
              ></DatePicker>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="用户性别" label-position="top">
              <Select v-model="conditionData.gender" placeholder="请选择用户性别" clearable>
                <Option value="1">男</Option>
                <Option value="0">女</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="学历信息" label-position="top">
              <Select clearable v-model="conditionData.education">
                <Option
                  v-for="item in eduList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="婚姻状态" label-position="top">
              <Select clearable v-model="conditionData.marital">
                <Option
                  v-for="item in marriedList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="所属行业" label-position="top">
              <Select clearable v-model="conditionData.industry">
                <Option
                  v-for="item in industryList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="职业信息" label-position="top">
              <Select clearable v-model="conditionData.profess">
                <Option
                  v-for="item in professList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="所属单位" required label-position="top">
              <Select filterable clearable v-model="conditionData.unit" @on-select="selectUnit">
                <Option
                  v-for="item in unitList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="上级单位用户是否接收任务" required label-position="top">
              <Checkbox v-model="single"></Checkbox>
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label-position="top">
              <Spin size="large" fix v-if="unitShow">
                <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                <div>正在加载...</div>
              </Spin>
              <Tree
                :data="unittreeData"
                ref="tree"
                expand-node
                v-if="conditionData.unit != undefined && conditionData.unit != ''"
                :select-node="false"
                show-checkbox
              ></Tree>
            </FormItem>
          </i-col>
        </Row>
      </Form>
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="draw = false">取消</Button>
        <Button type="primary" @click="confirmCondition">确定</Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
export default {
  name: "ReleaseScaleTask",
  data() {
    return {
      single: false,
      conditionData: {
        startDate: "",
        endDate: "",
        gender: "",
        education: "",
        marital: "",
        industry: "",
        profess: "",
        unit: "",
      },
      conditionItem: [],
      eduList: [],
      professList: [],
      industryList: [],
      marriedList: [],
      unitList: [],
      draw: false,
      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
      },
      checkUnitData: [],
      companyData: [],
      disabledDate: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now();
        },
      },
      checkScaleColumns: [
        {
          type: "index",
          title: "序号",
          align: "center",
          width: "40",
        },
        {
          title: "量表名",
          key: "scaleName",
          tooltip: true,
          ellipsis: true,
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: "40",
        },
      ],
      checkScaleData: [],

      theight: window.innerHeight - 225,
      task: {
        r_type: 2,
        unitId: [],
      },

      scaleData: [],
      scaleColumns: [
        {
          title: "量表名",
          key: "scaleName",
          tooltip: true,
          ellipsis: true,
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: "60",
        },
      ],
      scalesearching: true,
      scaleSize: 10,
      scaleNum: 1,
      scalekeyword: "",
      scaleTotal: 0,
      scaleShow: false,
      unitShow: false,
      unittreeData: [],
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 225),
      false
    );
    // this.getUnit();
    this.getScale();
    this.getMarriedList();
    this.getIndustryList();
    this.getEduList();
    this.getProfessList();
    this.getCompany();
  },
  methods: {
    selectUnit(option) {
      this.unitShow = true;
      this.$get("Customer/GetUnitTree", { unitId: option.value }).then(
        (res) => {
          this.unittreeData = this.extendData(res.data.responseList);
          this.unitShow = false;
        }
      );
    },
    extendData(dataArr) {
      dataArr.forEach((item, index) => {
        item.title = item.unitName;
        if (item.checked) {
          if (item.children && item.children.length > 0) {
            this.$set(item, "checked", false);
          } else {
            this.$set(item, "checked", true);
          }
        }
        if (item.children && item.children.length > 0) {
          this.extendData.call(this, item.children);
        }
      });
      return dataArr;
    },
    formatTree(array, f) {
      let groups = {};
      array.forEach(function (o) {
        let group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    },
    confirmCondition() {
      let nodesetting = [];
      let checkedStr = "";
      nodesetting = this.$refs.tree.getCheckedAndIndeterminateNodes();
      console.log(nodesetting)
      let trees = [];
      trees = this.formatTree(nodesetting, function (item) {
        return [item.parentId];
      });
      console.log(trees);
      for (let i = 0; i < trees.length; i++) {
        checkedStr += trees[i].title + ",";
      }
      let items = [];
      if (
        this.conditionData.startDate != undefined &&
        this.conditionData.startDate != ""
      ) {
        items.push("设置条件出生日期大于" + this.conditionData.startDate);
      }
      if (
        this.conditionData.endDate != undefined &&
        this.conditionData.endDate != ""
      ) {
        items.push("设置条件出生日期小于" + this.conditionData.endDate);
      }
      if (
        this.conditionData.gender != undefined &&
        this.conditionData.gender != ""
      ) {
        items.push("设置条件性别为" + this.conditionData.gender);
      }
      if (
        this.conditionData.education != undefined &&
        this.conditionData.education != ""
      ) {
        items.push("设置条件学历信息等于" + this.conditionData.education);
      }
      if (
        this.conditionData.marital != undefined &&
        this.conditionData.marital != ""
      ) {
        items.push("设置条件婚姻状态等于" + this.conditionData.marital);
      }
      if (
        this.conditionData.profess != undefined &&
        this.conditionData.profess != ""
      ) {
        items.push("设置条件职业信息等于" + this.conditionData.profess);
      }
      if (
        this.conditionData.industry != undefined &&
        this.conditionData.industry != ""
      ) {
        items.push("设置条件所属行业等于" + this.conditionData.industry);
      }
      this.conditionItem = items;
    },
    getCompany() {
      this.$get("Customer/GetCompanyList", {
        requestName: "getCompanyList",
      }).then((res) => {
        this.unitList = res.data.responseList;
      });
    },
    getProfessList() {
      this.$get("Customer/GetProfessList", {
        requestName: "getProfessList",
      }).then((res) => {
        this.professList = res.data.responseList;
      });
    },
    getEduList() {
      this.$get("Customer/GetEduList", {
        requestName: "getEduList",
      }).then((res) => {
        this.eduList = res.data.responseList;
      });
    },
    getIndustryList() {
      this.$get("Customer/GetIndustryList", {
        requestName: "getIndustryList",
      }).then((res) => {
        this.industryList = res.data.responseList;
      });
    },
    getMarriedList() {
      this.$get("Customer/GetMarriedList", {
        requestName: "getMarriedList",
      }).then((res) => {
        this.marriedList = res.data.responseList;
      });
    },
    selectUser() {
      this.draw = true;
    },
    checkCascader(value, selectedData) {
      this.checkUnitData = value;
    },
    getUnit() {
      this.$get("Customer/GetCompanyByCascader", {
        requestName: "getCompanyByCascader",
      }).then((res) => {
        this.companyData = this.filterArray(res.data.responseList);
      });
    },
    filterArray(list) {
      return list.map((item) => {
        item = Object.assign({}, item);
        if (item.level == 3) {
          delete item.children;
        } else {
          if (item.children && item.children.length > 0) {
            item.children = this.filterArray(item.children);
          }
        }
        return item;
      });
    },
    releaseTask() {
      if (this.task.taskName == undefined) {
        this.$Message.error("任务名称为必填项");
        return;
      }
      if (this.task.taskDesc == undefined) {
        this.$Message.error("任务说明为必填项");
        return;
      }
      if (this.checkScaleData.length <= 0) {
        this.$Message.error("至少选择一个量表");
        return;
      }
      if (this.task.taskDay == "") {
        this.$Message.error("任务结束时间为必选项");
        return;
      }
      if (this.checkUnitData.length <= 0) {
        this.$Message.error("单位/部门为必选项");
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确定发布?</p>",
        loading: true,
        onOk: () => {
          let adminid = localStorage.getItem("adminId");
          let units = "";
          this.checkUnitData.forEach((item) => (units += item + ","));
          let scales = "";
          this.checkScaleData.forEach((item) => (scales += item.scaleId + ","));
          console.log(
            this.task.taskName,
            this.task.taskDesc,
            this.task.taskDay,
            units,
            scales,
            adminid
          );
          this.$post("Task/SubmitGroupTask", {
            taskName: this.task.taskName,
            taskDesc: this.task.taskDesc,
            taskDay: this.task.taskDay,
            adminId: parseInt(adminid),
            units: units,
            scales: scales,
          }).then((res) => {
            if (res.data.code) {
              this.$Modal.remove();
              this.$Message.success(res.data.msg);
              this.$router.go(-1);
            } else {
              this.$Modal.remove();
              this.$Message.error(res.data.msg);
            }
          });
        },
        onCancel: () => {},
      });
    },
    removeScale(row) {
      this.checkScaleData = this.checkScaleData.filter(
        (item) => item.scaleId != row.scaleId
      );
    },
    selectScale(row) {
      if (
        this.checkScaleData.find((value) => value.scaleId == row.scaleId) !=
        undefined
      ) {
        this.$Message.error("此项已选");
        return;
      }
      this.checkScaleData.push(row);
    },
    getScale() {
      this.scaleShow = true;
      this.scalesearching = true;
      this.$get("Scale/GetScaleList", {
        requestName: "GetScaleList",
        typeId: -1,
        keyword: this.scalekeyword,
        statusCode: 1,
        pageSize: this.scaleSize,
        pageNum: this.scaleNum,
      }).then((scale) => {
        this.scaleTotal = scale.data.total;
        this.scaleData = scale.data.responseList;
        this.scalesearching = false;
        this.scaleShow = false;
      });
    },
    changeScalePage(e) {
      this.scaleNum = e;
      this.getScale();
    },
    changeScaleSize(e) {
      this.scaleSize = e;
      this.getScale();
    },
    searchScale() {
      this.getScale();
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.ivu-list-item-meta {
  text-align: left;
}

.ivu-list-item-meta-description {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.time {
  font-size: 14px;
  font-weight: bold;
}
.content {
  padding-left: 5px;
}
.ivu-timeline-item-content {
  top: -10px;
}
</style>